import { appAxios } from "./appService";

export default {
  async getFeature() {
    const response = await appAxios.get("/get-feature");
    return response.data;
  },
  async updateFeature(body) { 
    const response = await appAxios.put(`/admin/update-main-feature`, body);
    console.log("Main Service", response.data);
    return response.data;
  },
  async getFounderSlogan(){
    const response = await appAxios.get("/get-founder-slogan");
    return response.data;
  },
  async updateFounderSlogan(body){
    const response = await appAxios.put(`/admin/update-founder-slogan`, body);
    return response.data;
  },
  async getMainAbout(){
    const response = await appAxios.get("/get-main-about");
    return response.data;
  },
  async updateMainAbout(body){
    const response = await appAxios.put(`/admin/update-main-about`, body);
    return response.data;
  }
};