import router from "@/router";
import careerService from "@/services/careerService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    careers: [],
    loading: false,
  },
  getters: {
    getCareers: (state) => state.careers,
  },
  mutations: {
    SET_CAREERS(state, careers) {
      state.careers = careers;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initCareer({ commit }) {
      try {
        const response = await careerService.getAllCareers();

        if (response.status === "success") {
          commit("SET_CAREERS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async getCareerById(_, cId) {
      try {
        const response = await careerService.getCareerById(cId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createCareer({ commit }, formFields) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: formFields.name,
          description: formFields.description,
          en_name: formFields.en_name,
          en_description: formFields.en_description,
          is_fulltime: formFields.is_fulltime,
          is_active: formFields.is_active,
        };

        const response = await careerService.createCareer(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "career-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateCareer(_, formFields) {
      try {
        const body = {
          name: formFields.name,
          description: formFields.description,
          en_name: formFields.en_name,
          en_description: formFields.en_description,
          is_fulltime: formFields.is_fulltime,
          is_active: formFields.is_active,
        };

        const response = await careerService.updateCareer(formFields.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "career-list-page" });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async deleteCareer({ commit }, cId) {
      try {
        commit("SET_LOADING", true);
        const response = await careerService.deleteCareer(cId);

        if (response.status === "success") {
          commit(
            "SET_CAREERS",
            this.state.career.careers.filter((career) => career.id !== cId)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async updateSortOrder(_, ids) {
      try {

        const body = {
          positions: ids,
        };

        const response = await careerService.updateCareerSort(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
