import { appAxios } from "./appService";

export default {
  async getAllCollabs() {
    const response = await appAxios.get("/get-collabs");
    return response.data;
  },
  async createCollab(body) {
    const response = await appAxios.post("/admin/create-collab", body);
    return response.data;
  },
  async deleteCollab(id) {
    const response = await appAxios.delete(`/admin/delete-collab/${id}`);
    return response.data;
  },
  async getCollabById(id) {
    const response = await appAxios.get(`/admin/get-collab/${id}`);
    return response.data;
  } ,
  async updateCollab(id, body) { 
    const response = await appAxios.put(`/admin/update-collab/${id}`, body);
    return response.data;
  }
};