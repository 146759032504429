import { appAxios } from "./appService";

export default {
  async getAbout() {
    const response = await appAxios.get(`/get-abouts`);
    return response.data;
  },
  async updateAbout(body) {
    const response = await appAxios.put(`/admin/update-abouts`, body);
    return response.data;
  },
};
