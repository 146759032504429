import { appAxios } from "./appService";

export default {
  async uploadFile(formData) {
    const response = await appAxios.post("/admin/upload-file", formData, {
      headers: {
        "Content-Type": "image/jpeg",
      },
    });
    return response.data;
  },
};