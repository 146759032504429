import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

// NProgress
import 'nprogress/nprogress.css'

// Notify
import Notifications from '@kyvg/vue3-notification'

// Config
import config from '../config'

// Quill editor
import { quillEditor } from 'vue3-quill'

const vuetify = createVuetify({
  components,
  directives,
  iconfont: 'mdi'
})

const app = createApp(App)
app.use(vuetify)
app.use(store)
app.use(router)
app.use(Notifications)
app.use(quillEditor)

app.config.globalProperties.$config = config;

app.mount('#app')
