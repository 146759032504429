import { appAxios } from "./appService";

export default {
  async getMertLife() { // add page
    const response = await appAxios.get("/get-mlife");
    return response.data;
  },
  async createJourney(body) {
    const response = await appAxios.post("/admin/add-journey", body);
    return response.data;
  },
  async deleteJourney(id) {
    const response = await appAxios.delete(`/admin/delete-journey/${id}`);
    return response.data;
  },
  async uploadImage(formData) {
    const response = await appAxios.post("/offer/discount-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
  async getJourneyById(id) {
    const response = await appAxios.get(`/admin/get-journey/${id}`);
    return response.data;
  } ,
  async updateMertLife(body) { 
    const response = await appAxios.put(`/update-mlife`, body);
    return response.data;
  }
};