import aboutService from "@/services/aboutService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    getProducts: (state) => state.products,
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async getAbout() {
      try {
        const response = await aboutService.getAbout();
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateAbout(_, payload) {
      try {
        const body = {
          about: payload.about,
          en_about: payload.en_about,
          misyon: payload.misyon,
          en_misyon: payload.en_misyon,
          vision: payload.vision,
          en_vision: payload.en_vision,
        };

        const response = await aboutService.updateAbout(body);

        if (response.status === "success") {
          notify({
            type: "success",
            title: "Başarılı",
            text: "Hakkımızda içeriği başarıyla güncellendi.",
          });
        }

        return response;
      } catch (error) {
        notify({
          type: "error",
          title: "Hata",
          text: "Hakkımızda içeriği güncellenirken bir hata oluştu.",
        });
      }
    },
  },
};
