import router from "@/router";
import aprojectService from "@/services/aprojectService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    aprojects: [],
    loading: false,
  },
  getters: {
    getAProjects: (state) => state.aprojects,
  },
  mutations: {
    SET_APROJECTS(state, aprojects) {
      state.aprojects = aprojects;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initAProject({ commit }, page) {
      try {
        const response = await aprojectService.getAllAProjects(page);

        if (response.status === "success") {
          commit("SET_APROJECTS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createAProject({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          image: form.image,
        };

        const response = await aprojectService.createAProject(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "aproject-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteAProject({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await aprojectService.deleteAProject(id);

        if (response.status === "success") {
          commit(
            "SET_APROJECTS",
            this.state.aproject.aprojects.filter(
              (aproject) => aproject.id !== id
            )
          );

          notify({
            type: "success",
            text: response.message,
          });

        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async getAProjectById(_, id) {
      try {
        const response = await aprojectService.getAProjectById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateAProject(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
        };

        if (form.image) {
          body.image = form.image;
        }

        const response = await aprojectService.updateAProject(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "aproject-list-page" });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
