import uploadService from "@/services/uploadService";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    
    async uploadFile(_, file) {
      try {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("dir", "");

        const response = await uploadService.uploadFile(formData);

        if (response.status === "error") {
          return null;
        }

        return response.data;
      } catch (error) {
        console.log("HATA =>",error);
      }
    },

    
  },
};
