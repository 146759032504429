import router from "@/router";
import contactService from "@/services/contactService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    contacts: [],
    loading: false,
  },
  getters: {
    getContacts: (state) => state.contacts,
  },
  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initContacts({ commit }) {
      try {
        const response = await contactService.getAllContacts();

        if (response.status === "success") {
          commit("SET_CONTACTS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createContact({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          top_title: form.top_title,
          en_top_title: form.en_top_title,
          title: form.title,
          en_title: form.en_title,
          address: form.address,
          en_address: form.en_address,
          phone: form.phone,
          fax: form.fax,
          email: form.email,
          seller_email: form.seller_email,
          description: form.description,
          en_description: form.en_description,
        };

        if (form.map !== "") {
          if (form.map.includes("width")) {
            const map = form.map.replace("width", "100%");
            body.map = map;
          } else {
            body.map = form.map;
          }
        }

        const response = await contactService.createContact(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "contact-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteContact({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await contactService.deleteContact(id);

        if (response.status === "success") {
          commit(
            "SET_CONTACTS",
            this.state.contact.contacts.filter((contact) => contact.id !== id)
          );
          notify({
            type: "success",
            text: "İletişim bilgisi başarıyla silindi.",
          });
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async getContactById(_, id) {
      try {
        const response = await contactService.getContactById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateContact(_, form) {
      try {
        const body = {
          top_title: form.top_title,
          en_top_title: form.en_top_title,
          title: form.title,
          en_title: form.en_title,
          address: form.address,
          en_address: form.en_address,
          phone: form.phone,
          fax: form.fax,
          email: form.email,
          seller_email: form.seller_email,
          description: form.description,
          en_description: form.en_description,
        };

        if (form.map !== "") {
          // width parametresinin değeri regex ile bulunur ve 100% ile değiştirilir
          let map = form.map.replace(/width="\d+[^"]*"/, 'width="100%"');
          // height parametresinin değeri regex ile bulunur ve 428px ile değiştirilir
          map = map.replace(/height="\d+[^"]*"/, 'height="428px"');
          body.map = map;
        } else {
          body.map = form.map;
        }
        
        const response = await contactService.updateContact(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "contact-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
