export default [
    {
      path: "/sector",
      name: "sector-list-page",
      component: () => import( "../views/sector/sector-list-page.vue"),
    },
    {
      path: "/sector-create",
      name: "sector-create-page",
      component: () => import("../views/sector/sector-create-page.vue"),
    },
    {
      path: "/sector-update/:id",
      name: "sector-update-page",
      component: () => import("../views/sector/sector-update-page.vue"),
      props: true,
    },
  ];
  