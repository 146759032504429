import appsettingsService from "@/services/appsettingsService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    settings: {},
  },
  getters: {
    getSettings: (state) => state.settings,
  },
  mutations: {
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    async initSettings({ commit }) {
      try {
        const response = await appsettingsService.getSettings();
        if (response.status === "success") {
          commit("SET_SETTINGS", response.data);
        }

        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    async updateSettings(_, form) {
      try {
        const body = {
          email: form.email,
        };

        const response = await appsettingsService.updateSettings(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
