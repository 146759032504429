import router from "@/router";
import policieService from "@/services/policieService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    policies: [],
    loading: false,
  },
  getters: {
    getPolicies: (state) => state.policies,
  },
  mutations: {
    SET_POLICIES(state, policies) {
      state.policies = policies;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initPolicies({ commit }) {
      try {
        const response = await policieService.getAllPolicies();

        if (response.status === "success") {
          commit("SET_POLICIES", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createPolicie({ commit }, policie) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: policie.name,
          content: policie.content,
          en_name: policie.en_name,
          en_content: policie.en_content,
          image: policie.image,
          active: policie.active,
        };

        const response = await policieService.createPolicie(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "policie-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async deletePolicie({ commit }, policieId) {
      try {
        commit("SET_LOADING", true);
        const response = await policieService.deletePolicie(policieId);

        if (response.status === "success") {
          commit(
            "SET_POLICIES",
            this.state.policie.policies.filter(
              (policie) => policie.id !== policieId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async initPolicie(_, policieId) {
      try {
        const response = await policieService.getPolicieById(policieId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updatePolicie(_, policie) {
      try {
        const body = {
          name: policie.name,
          content: policie.content,
          en_name: policie.en_name,
          en_content: policie.en_content,
          image: policie.image,
          active: policie.active,
        };

        const response = await policieService.updatePolicie(policie.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "policie-list-page" });
        }

      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
  },
};
