import { appAxios } from "./appService";

export default {
  async getAllBooks() {
    const response = await appAxios.get("/get-books");
    return response.data;
  },
  async createBook(body) {
    const response = await appAxios.post("/create-book", body);
    return response.data;
  },
  async deleteBook(bId) {
    const response = await appAxios.delete(`/delete-book/${bId}`);
    return response.data;
  },
  async updateBook(bId, body) {
    const response = await appAxios.put(`/update-book/${bId}`, body);
    return response.data;
  },
  async getBookById(bId) {
    const response = await appAxios.get(`/get-book/${bId}`);
    return response.data;
  },
};
