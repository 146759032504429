export default [
    {
      path: "/policies",
      name: "policie-list-page",
      component: () => import("../views/policie/policie-list-page.vue"),
    },
    {
      path: "/create-policie",
      name: "create-policie-page",
      component: () => import("../views/policie/create-policie-page.vue"),
    },
    {
      path: "/update-policie/:id",
      name: "update-policie-page",
      component: () => import("../views/policie/update-policie-page.vue"),
      props: true
    }
  ];
  