import { appAxios } from "./appService";

export default {
  async getAllContacts() {
    const response = await appAxios.get("/get-contacts");
    return response.data;
  },
  async createContact(body) {
    const response = await appAxios.post("/admin/create-contact", body);
    return response.data;
  },
  async deleteContact(id) {
    const response = await appAxios.delete(`/admin/delete-contact/${id}`);
    return response.data;
  },
  async getContactById(id) {
    const response = await appAxios.get(`/get-contact/${id}`);
    return response.data;
  } ,
  async updateContact(id, body) { 
    const response = await appAxios.put(`/admin/update-contact/${id}`, body);
    return response.data;
  }
};