import userService from "@/services/userService";

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    getUsers: (state) => state.users,
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    }
  },
  actions: {
    async getUserCount() {
      try {
        const response = await userService.getUserCount();

        if (response.status === "success") {
          return response.data.count;
        }

        return 0;
      } catch (error) {
        return error.response;
      }
    },
    async initUsers({ commit }) {
      try {
        const response = await userService.getUsers();
        if (response.status === "success") {
          commit("SET_USERS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
  },
};
