import { appAxios } from "./appService";

export default {
  async getArge() {
    const response = await appAxios.get("/get-arge");
    return response.data;
  },
  async updateText(body) {
    const response = await appAxios.put('/admin/update-arge-text', body);
    return response.data;
  },
  async updateAbout(body) {
    const response = await appAxios.put('/admin/update-arge-about', body);
    return response.data;
  },
  async updateImage(body) {
    const response = await appAxios.put('/admin/update-arge-banner-image', body);
    return response.data;
  },
};
