import { appAxios } from "./appService";

export default {
  async getAllSectors() {
    const response = await appAxios.get("/get-sectors");
    return response.data;
  },
  async createSector(body) {
    const response = await appAxios.post("/admin/create-sector", body);
    return response.data;
  },
  async deleteSector(id) {
    const response = await appAxios.delete(`/admin/delete-sector/${id}`);
    return response.data;
  },
  async getSectorById(id) {
    const response = await appAxios.get(`/get-sector/${id}`);
    return response.data;
  } ,
  async updateSector(id, body) { 
    const response = await appAxios.put(`/admin/update-sector/${id}`, body);
    return response.data;
  }
};