<template>
  <app-bar :drawer="drawer" @updateDrawerValue="updateDrawer"></app-bar>
  <v-navigation-drawer v-model="drawer" style="z-index: 99999;">
    <div class="navbar-logo-container">
      <img
        src="../assets/images/logo.jpg"
        alt=""
        class="navbar-logo"
      />
    </div>
    <v-list density="compact" nav>
      <v-list-item
        prepend-icon="mdi-view-dashboard"
        title="Ana sayfa"
        value="mainpage"
        @click="navigateTo('/main-page')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-toolbox-outline"
        title="Hizmetler"
        value="service"
        @click="navigateTo('/service')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-highway"
        title="Yolculuğumuz"
        value="journey"
        @click="navigateTo('/journey')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-chart-ppf"
        title="Ürünler"
        value="product"
        @click="navigateTo('/product')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-information-outline"
        title="Hakkımızda"
        value="about"
        @click="navigateTo('/about')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-text"
        title="Politikalarımız"
        value="policies"
        @click="navigateTo('/policies')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-rhombus-split-outline"
        title="Çözümlerimiz"
        value="solition"
        @click="navigateTo('/solition')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-newspaper-variant-outline"
        title="Bülten"
        value="newsletter"
        @click="navigateTo('/main-newsletter')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-post-outline"
        title="Blog"
        value="blog"
        @click="navigateTo('/blog')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-nature-people-outline"
        title="Mert'te hayat"
        value="people-and-culture"
        @click="navigateTo('/people-and-culture')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-account-hard-hat-outline"
        title="Tamamlanan İşler"
        value="works"
        @click="navigateTo('/works')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-domain"
        title="Grup Şirketler"
        value="group-company"
        @click="navigateTo('/gcomp')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-book-multiple"
        title="Kitapçıklar"
        value="books"
        @click="navigateTo('/book')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-layers-outline"
        title="Sistemler"
        value="system"
        @click="navigateTo('/system')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-application"
        title="Referans Uygulamalar"
        value="referencep"
        @click="navigateTo('/referencep')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-sitemap-outline"
        title="Sektörler"
        value="sector"
        @click="navigateTo('/sector')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-cog-outline"
        title="Site Ayarları"
        value="app-settings"
        @click="navigateTo('/app-settings')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-developer-board"
        title="AR-GE"
        value="arge"
        @click="navigateTo('/arge')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-blur"
        title="AR-GE Projeler"
        value="argeproject"
        @click="navigateTo('/aproject')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-consolidate"
        title="AR-GE| İş Birlikleri"
        value="collab"
        @click="navigateTo('/collab')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-briefcase-outline"
        title="Kariyer"
        value="career"
        @click="navigateTo('/career')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-human-greeting-proximity"
        title="İletişim"
        value="contact"
        @click="navigateTo('/contact')"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import AppBar from './AppBar.vue';
export default {
  name: "navigation-side",
  components: {
    AppBar,
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    updateDrawer(value) {
      this.drawer = value;
    },
  },
};
</script>
