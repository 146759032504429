import router from "@/router";
import collabService from "@/services/collabService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    collabs: [],
    loading: false,
  },
  getters: {
    getCollabs: (state) => state.collabs,
  },
  mutations: {
    SET_COLLAB(state, collabs) {
      state.collabs = collabs;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initCollabs({ commit }, page) {
      try {
        const response = await collabService.getAllCollabs(page);

        if (response.status === "success") {
          commit("SET_COLLAB", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createCollab({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          image: form.image,
        };

        const response = await collabService.createCollab(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "collab-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteCollab({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await collabService.deleteCollab(id);

        if (response.status === "success") {
          commit(
            "SET_COLLAB",
            this.state.collab.collabs.filter((collab) => collab.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getCollabById(_, id) {
      try {
        const response = await collabService.getCollabById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateCollab(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
        };

        if (form.image) {
          body.image = form.image;
        }

        const response = await collabService.updateCollab(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "collab-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
