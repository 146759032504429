export default [
    {
      path: "/aproject",
      name: "aproject-list-page",
      component: () => import( "../views/aproject/aproject-list-page.vue"),
    },
    {
      path: "/aproject-create",
      name: "aproject-create-page",
      component: () => import("../views/aproject/aproject-create-page.vue"),
    },
    {
      path: "/aproject-update/:id",
      name: "aproject-update-page",
      component: () => import("../views/aproject/aproject-update-page.vue"),
      props: true,
    },
  ];
  