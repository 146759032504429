export default [
    {
      path: "/blog",
      name: "blog-list-page",
      component: () => import("../views/blog/blog-list-page.vue"),
    },
    {
      path: "/create-blog",
      name: "create-blog-page",
      component: () => import("../views/blog/create-blog-page.vue"),
    },
    {
      path: "/update-blog/:id",
      name: "update-blog-page",
      component: () => import("../views/blog/update-blog-page.vue"),
      props: true
    }
  ];
  