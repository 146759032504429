import router from "@/router";
import gcompService from "@/services/gcompService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    gcomps: [],
    loading: false,
  },
  getters: {
    getGcomps: (state) => state.gcomps,
  },
  mutations: {
    SET_GCOMPS(state, gcomps) {
      state.gcomps = gcomps;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initGcomps({ commit }) {
      try {
        const response = await gcompService.getAllGcomps();

        if (response.status === "success") {
          commit("SET_GCOMPS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createGcomp({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: form.name,
          description: form.description,
          en_description: form.en_description,
          link: form.link,
          logo: form.logo,
          hover_logo: form.hover_logo,
          bg_image: form.bg_image,
        };

        const response = await gcompService.createGcomp(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "gcomp-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteGcomp({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await gcompService.deleteGcomp(id);

        if (response.status === "success") {
          commit(
            "SET_GCOMPS",
            this.state.gcomp.gcomps.filter((gcomp) => gcomp.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getGcompById(_, id) {
      try {
        const response = await gcompService.getGcompById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateGcomp(_, form) {
      try {
        const body = {
            name: form.name,
            description: form.description,
            en_description: form.en_description,
            link: form.link,
            logo: form.logo,
            hover_logo: form.hover_logo,
            bg_image: form.bg_image,
          };

        const response = await gcompService.updateGcomp(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "gcomp-list-page" });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
