const BANKS = [
  { name: "World", value: "world", offer: "" },
  { name: "Bonus", value: "bonus", offer: "" },
  { name: "Axess", value: "axess", offer: "" },
  { name: "Ziraat", value: "ziraat", offer: "" },
  { name: "Vakıfbank", value: "vakifbank", offer: "" },
  { name: "Maximum", value: "maximum", offer: "" },
  { name: "Card Finans", value: "card_finans", offer: "" },
  { name: "Advantage", value: "advantage", offer: "" },
  { name: "Enpara", value: "enpara", offer: "" },
  { name: "Sağlam Kart", value: "saglam_kart", offer: "" },
  { name: "Paraf", value: "paraf", offer: "" },
  { name: "Ptt Bank", value: "ptt_bank", offer: "" },
  { name: "Diğer", value: "other", offer: "" },
];

module.exports = {
  BANKS
};