import { appAxios } from "./appService";

export default {
  async getAllCareers() {
    const response = await appAxios.get("/admin/get-positions-for-admin");
    return response.data;
  },
  async getCareerById(cId) {
    const response = await appAxios.get(`/get-position/${cId}`);
    return response.data;
  },
  async createCareer(body) {
    const response = await appAxios.post("/admin/create-position", body);
    return response.data;
  },
  async updateCareer(cId, body) {
    const response = await appAxios.put(`/admin/update-position/${cId}`, body);
    return response.data;
  },
  async deleteCareer(cId) {
    const response = await appAxios.delete(`/admin/delete-position/${cId}`);
    return response.data;
  },
  async updateCareerSort(body) {
    const response = await appAxios.put("/admin/update-position-sort", body);
    return response.data;
  }
};
