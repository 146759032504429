import { appAxios } from "./appService";

export default {
  async getAllWorks() {
    const response = await appAxios.get("/get-works");
    return response.data;
  },
  async createWork(body) {
    const response = await appAxios.post("/admin/create-work", body);
    return response.data;
  },
  async deleteWork(id) {
    const response = await appAxios.delete(`/admin/delete-work/${id}`);
    return response.data;
  },
  async getWorkById(id) {
    const response = await appAxios.get(`/admin/get-work/${id}`);
    return response.data;
  } ,
  async updateWork(id, body) { 
    const response = await appAxios.put(`/admin/update-work/${id}`, body);
    return response.data;
  }
};