export default [
  {
    path: "/main-newsletter",
    name: "main-newsletter",
    component: () => import("../views/newsletter/main-newsletter.vue"),
  },
  {
    path: "/newsletter",
    name: "newsletter-list-page",
    component: () => import("../views/newsletter/pages/newsletter-list-page.vue"),
  },
  {
    path: "/create-newsletter",
    name: "create-newsletter-page",
    component: () => import("../views/newsletter/pages/create-newsletter-page.vue"),
  },
  {
    path: "/update-newsletter/:id",
    name: "update-newsletter-page",
    component: () => import("../views/newsletter/pages/update-newsletter-page.vue"),
    props: true,
  },
  {
    path: "/newsletter-category",
    name: "newsletter-category-list-page",
    component: () => import("../views/newsletter/pages/newsletter-category-list-page.vue"),
  },
  {
    path: "/create-newsletter-category",
    name: "create-newsletter-category-page",
    component: () => import("../views/newsletter/pages/create-newsletter-category-page.vue"),
  },
  {
    path: "/update-newsletter-category/:id",
    name: "update-newsletter-category-page",
    component: () => import("../views/newsletter/pages/update-newsletter-category-page.vue"),
    props: true,
  },
];
