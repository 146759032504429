export default [
    {
      path: "/book",
      name: "list-books-page",
      component: () => import( "../views/book/list-books-page.vue"),
    },
    {
      path: "/book-create",
      name: "create-book-page",
      component: () => import("../views/book/create-book-page.vue"),
    },
    {
      path: "/book-update/:id",
      name: "update-book-page",
      component: () => import("../views/book/update-book-page.vue"),
      props: true,
    },
  ];
  