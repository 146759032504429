import router from "@/router";
import referenceprojectService from "@/services/referenceprojectService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    referenceProjects: [],
    loading: false,
  },
  getters: {
    getReferenceProjects: (state) => state.referenceProjects,
  },
  mutations: {
    SET_DATAS(state, payloads) {
      state.referenceProjects = payloads;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initReferenceProject({ commit }, page) {
      try {
        const response = await referenceprojectService.getAllReferenceProjects(page);

        if (response.status === "success") {
          commit("SET_DATAS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createReferenceProject({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          image: form.image,
        };

        const response = await referenceprojectService.createReferenceProject(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "referencep-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteReferenceProject({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await referenceprojectService.deleteReferenceProject(id);

        if (response.status === "success") {
          commit(
            "SET_DATAS",
            this.state.referencep.referenceProjects.filter((referenceProject) => referenceProject.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getReferenceProjectById(_, id) {
      try {
        const response = await referenceprojectService.getReferenceProjectById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateReferenceProject(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
        };

        if (form.image) {
          body.image = form.image;
        }

        const response = await referenceprojectService.updateReferenceProject(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "referencep-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
