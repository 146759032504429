import { appAxios } from "./appService";

export default {
  async getUserCount() {
    const response = await appAxios.get("/user/count");
    return response.data;
  },
  async getUsers() {
    const response = await appAxios.get("/user");
    return response.data;
  },
};
