// import router from "@/router";
import argeService from "@/services/argeService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    arge: null,
    loading: false,
  },
  getters: {
    getArge: (state) => state.arge,
  },
  mutations: {
    SET_ARGE(state, arge) {
      state.arge = arge;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initArge({ commit }) {
      try {
        const response = await argeService.getArge();

        if (response.status === "success") {
          commit("SET_ARGE", response.data);
          return response.data;
        }

        return {};
      } catch (error) {
        return error.response;
      }
    },

    async updateText(_, form) {
      try {
        const body = {
          title: form.title,
          en_title: form.en_title,
          description: form.description,
          en_description: form.en_description,
        };

        const response = await argeService.updateText(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Arge içeriği başarıyla güncellendi.",
          });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateAbout(_, form) {
      try {
        const body = {
          about_description: form.about_description,
          en_about_description: form.en_about_description,
        };

        const response = await argeService.updateAbout(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Arge Hakkımızda içeriği başarıyla güncellendi.",
          });
        }
      }
      catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateImage(_, form) {
      try {
        const body = {
          banner_image: form.banner_image,
        };

        const response = await argeService.updateImage(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: "Arge banner resmi başarıyla güncellendi.",
          });
        }
      }
      catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    }

   
  },
};
