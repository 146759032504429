export default [
  {
    path: "/service",
    name: "service-list-page",
    component: () => import("../views/service/service-list-page.vue"),
  },
  {
    path: "/create-service",
    name: "create-service",
    component: () => import("../views/service/create-service-page.vue"),
  },
  {
    path: "/update-service/:id",
    name: "update-service",
    component: () => import("../views/service/update-service-page.vue"),
    props: true
  }
];
