export default [
  {
    path: "/system",
    name: "system-list-page",
    component: () => import( "../views/system/system-list-page.vue"),
  },
  {
    path: "/system-create",
    name: "system-create-page",
    component: () => import("../views/system/system-create-page.vue"),
  },
  {
    path: "/system-update/:id",
    name: "system-update-page",
    component: () => import("../views/system/system-update-page.vue"),
    props: true,
  },
];
