export default [
    {
      path: "/gcomp",
      name: "gcomp-list-page",
      component: () => import( "../views/gcomps/gcomp-list-page.vue"),
    },
    {
      path: "/gcomp-create",
      name: "gcomp-create-page",
      component: () => import("../views/gcomps/gcomp-create-page.vue"),
    },
    {
      path: "/gcomp-update/:id",
      name: "gcomp-update-page",
      component: () => import("../views/gcomps/gcomp-update-page.vue"),
      props: true,
    },
  ];
  