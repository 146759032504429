import { appAxios } from "./appService";

export default {
  async getAllPolicies() {
    const response = await appAxios.get("/get-policies");
    return response.data;
  },
  async createPolicie(body) {
    const response = await appAxios.post("/admin/create-policie", body);
    return response.data;
  },
  async deletePolicie(policieId) {
    const response = await appAxios.delete(`/admin/delete-policie/${policieId}`);
    return response.data;
  },
  async updatePolicie(policieId, body) {
    const response = await appAxios.put(`/admin/update-policie/${policieId}`, body);
    return response.data;
  },
  async getPolicieById(policieId) {
    const response = await appAxios.get(`/get-policie/${policieId}`);
    return response.data;
  },
};
