import bookService from "@/services/bookService";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    loading: false,
    books: [],
  },
  getters: {
    getBooks: (state) => state.books,
  },
  mutations: {
    SET_BOOKS(state, books) {
      state.books = books;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initBooks({ commit }) {
      try {
        const response = await bookService.getAllBooks();
        if (response.status === "success") {
          commit("SET_BOOKS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },

    async getBookById(_, id) {
      try {
        const response = await bookService.getBookById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async createBook({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          en_title: form.en_title,
          image: form.image,
          pdf: form.pdf,
          for: form.for,
        };

        const response = await bookService.createBook(body);
        console.log("Response verisi =>", response);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "list-books-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
    
    async updateBook(_, form) {
      try {
        const body = {
          title: form.title,
          en_title: form.en_title,
          for: form.for
        };

        if (form.image) {
          body.image = form.image;
        }

        if (form.pdf) {
          body.pdf = form.pdf;
        }

        const response = await bookService.updateBook(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "list-books-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteBook({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await bookService.deleteBook(id);

        if (response.status === "success") {
          commit(
            "SET_BOOKS",
            this.state.book.books.filter((book) => book.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

  },
};
