export default [
    {
      path: "/product",
      name: "product-list-page",
      component: () => import("../views/product/product-list-page.vue"),
    },
    {
      path: "/create-product",
      name: "create-product-page",
      component: () => import("../views/product/create-product-page.vue"),
    },
    {
      path: "/update-product/:id",
      name: "update-product-page",
      component: () => import("../views/product/update-product-page.vue"),
      props: true
    }
  ];
  