// import router from "@/router";
import pacService from "@/services/pacService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    mlife: null,
    loading: false,
  },
  getters: {
    getMlife: (state) => state.mlife,
    getTotalPages: (state) => state.totalPages,
  },
  mutations: {
    SET_MLIFE(state, mlife) {
      state.mlife = mlife;
    },
    SET_TOTAL_PAGES(state, totalPages) {
      state.totalPages = totalPages - 1;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initMlife() {
      try {
        const response = await pacService.getMertLife();

        if (response.status === "success") {
          return response.data;
        }

        return {};
      } catch (error) {
        return error.response;
      }
    },

    async updateMlife(_, form) {
      try {
        const body = {
          title: form.title,
          en_title: form.en_title,
          content: form.content,
          en_content: form.en_content,
        };

        const response = await pacService.updateMertLife(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
  },
};
