import { appAxios } from "./appService";

export default {
  async getAllBlogs() {
    const response = await appAxios.get("/get-blogs");
    return response.data;
  },
  async createBlog(body) {
    const response = await appAxios.post("/create-blog", body);
    return response.data;
  },
  async deleteBlog(bId) {
    const response = await appAxios.delete(`/delete-blog/${bId}`);
    return response.data;
  },
  async updateBlog(bId, body) {
    const response = await appAxios.put(`/update-blog/${bId}`, body);
    return response.data;
  },
  async getBlogById(bId) {
    const response = await appAxios.get(`/get-blog/${bId}`);
    return response.data;
  },
};
