export default [
    {
      path: "/career",
      name: "career-list-page",
      component: () => import("../views/career/career-list-page.vue"),
    },
    {
      path: "/create-career",
      name: "create-career-page",
      component: () => import("../views/career/create-career-page.vue"),
    },
    {
      path: "/update-career/:id",
      name: "update-career-page",
      component: () => import("../views/career/update-career-page.vue"),
      props: true
    }
  ];
  