import { appAxios } from "./appService";

export default {
  async getSettings() {
    const response = await appAxios.get("/admin/get-settings");
    return response.data;
  },

  async updateSettings(body) {
    const response = await appAxios.put("/admin/update-settings", body);
    return response.data;
  },
};