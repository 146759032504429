import serviceRoutes from "./service-routes.js";
import journeyRoutes from "./journey-routes";
import productRoutes from "./product-routes";
import policieRoutes from "./policie-routes.js";
import solitionRoutes from "./solition-routes.js";
import newsletterRoutes from "./newsletter-routes.js";
import blogRoutes from "./blog-routes.js";
import pacRoutes from "./pac-routes.js";
import bookRoutes from "./book-routes.js";
import argeRoutes from "./arge-routes.js";
import contactRoutes from "./contact-routes.js";
import workRoutes from "./work-routes.js";
import gcompRoutes from "./gcomp-routes.js";
import systemRoutes from "./system-routes.js";
import referenceprojectRoutes from "./referenceproject-routes.js";
import sectorRoutes from "./sector-routes.js";
import aprojectRoutes from "./aproject-routes.js";
import collabRoutes from "./collab-routes.js";
import careerRoutes from "./career-routes.js";

const childRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import( "../views/dashboard/dashboard-page.vue"),
  },
  {
    path: "/main-page",
    name: "main-page",
    component: () => import( "../views/mainpage/main-page.vue"),
  },
  {
    path: "/user",
    name: "user-list-page",
    component: () => import( "../views/user/user-list-page.vue"),
  },
  {
    path: "/app-settings",
    name: "app-settings-page",
    component: () => import( "../views/appsetting/app-settings-page.vue"),
  },
  {
    path: "/about",
    name: "about-page",
    component: () => import( "../views/about/about-page.vue"),
  },
  {
    path: "/content/:section/:title",
    name: "content-page",
    component: () => import( "../views/content/update-content-page.vue"),
    props: true
  },
  ...serviceRoutes,
  ...journeyRoutes,
  ...productRoutes,
  ...policieRoutes,
  ...solitionRoutes,
  ...newsletterRoutes,
  ...blogRoutes,
  ...pacRoutes,
  ...bookRoutes,
  ...argeRoutes,
  ...contactRoutes,
  ...workRoutes,
  ...gcompRoutes,
  ...systemRoutes,
  ...referenceprojectRoutes,
  ...sectorRoutes,
  ...aprojectRoutes,
  ...collabRoutes,
  ...careerRoutes
];

export default childRoutes;
