export default [
  {
    path: "/contact",
    name: "contact-list-page",
    component: () => import( "../views/contact/contact-list-page.vue"),
  },
  {
    path: "/contact-create",
    name: "contact-create-page",
    component: () => import("../views/contact/contact-create-page.vue"),
  },
  {
    path: "/contact-update/:id",
    name: "contact-update-page",
    component: () => import("../views/contact/contact-update-page.vue"),
    props: true,
  },
];
