export default [
    {
      path: "/referencep",
      name: "referencep-list-page",
      component: () => import( "../views/referencep/referencep-list-page.vue"),
    },
    {
      path: "/referencep-create",
      name: "referencep-create-page",
      component: () => import("../views/referencep/referencep-create-page.vue"),
    },
    {
      path: "/referencep-update/:id",
      name: "referencep-update-page",
      component: () => import("../views/referencep/referencep-update-page.vue"),
      props: true,
    },
  ];
  