export default [
    {
      path: "/collab",
      name: "collab-list-page",
      component: () => import( "../views/collab/collab-list-page.vue"),
    },
    {
      path: "/collab-create",
      name: "collab-create-page",
      component: () => import("../views/collab/collab-create-page.vue"),
    },
    {
      path: "/collab-update/:id",
      name: "collab-update-page",
      component: () => import("../views/collab/collab-update-page.vue"),
      props: true,
    },
  ];
  