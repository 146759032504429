<template>
  <div class="auth-main-wrapper">
    <v-card class="auth-signin-card" :loading="isLoading" variant="outlined">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
          <img src="../../assets/images/logo.jpg" width="200" alt="" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
          <h1 class="text-h6">Panel Giriş</h1>
        </v-col>
      </v-row>

      <form @submit.prevent="signIn">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
            <v-text-field
              label="Kullanıcı Adı"
              outlined
              autofocus
              dense
              class="auth-input"
              v-model="username"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
            <v-text-field
              v-model="password"
              :append-icon="showp ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showp ? 'text' : 'password'"
              label="Parola"
              @click:append="showp = !showp"
              outlined
              dense
              class="auth-input"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
            <router-link to="/" class="text-decoration-none">
              <span class="text-caption">Forgot Password?</span>
            </router-link>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
            <v-btn
              color="#1483D6"
              class="auth-btn"
              style="color:white;"
              outlined
              rounded
              @click="signIn"
              :disabled="username === '' || password === ''"
            >
              Giriş Yap
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "sign-in-page",
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      showp: false,
    };
  },

  methods: {
    ...mapActions({
      doSignIn: "auth/doSignIn",
    }),

    signIn() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.doSignIn({
          username: this.username,
          password: this.password,
        })
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      }, 2000);
    },
  },
};
</script>
