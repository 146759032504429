import { createStore } from 'vuex'
import authModule from './modules/auth'
import userModule from './modules/user'
import sectorModule from './modules/sector'
import serviceModule from './modules/service'
import enumsModule from './modules/enums'
import journeyModule from './modules/journey'
import mainpageModule from './modules/mainpage'
import productModule from './modules/product'
import aboutModule from './modules/about'
import policieModule from './modules/policie'
import solitionModule from './modules/solition'
import newsletterModule from './modules/newsletter'
import blogModule from './modules/blog'
import pacModule from './modules/pac'
import uploadModule from './modules/upload'
import bookModule from './modules/book'
import argeModule from './modules/arge'
import contactModule from './modules/contact'
import contentModule from './modules/content'
import workModule from './modules/work'
import gcompModule from './modules/gcomp'
import appsettingsModule from './modules/appsettings'
import systemModule from './modules/system'
import referencepModule from './modules/referencep'
import aprojectModule from './modules/aproject'
import collabModule from './modules/collab'
import careerModule from './modules/career'

export default createStore({
  modules: {
    auth: authModule,
    user: userModule,
    sector: sectorModule,
    service: serviceModule,
    enums: enumsModule,
    journey: journeyModule,
    mainpage: mainpageModule,
    product: productModule,
    about: aboutModule,
    policie: policieModule,
    solition: solitionModule,
    newsletter: newsletterModule,
    blog: blogModule,
    pac: pacModule,
    upload: uploadModule,
    book: bookModule,
    arge: argeModule,
    contact: contactModule,
    content: contentModule,
    work: workModule,
    gcomp: gcompModule,
    setting: appsettingsModule,
    system: systemModule,
    referencep: referencepModule,
    aproject: aprojectModule,
    collab: collabModule,
    career: careerModule
  }
})
