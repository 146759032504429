import router from "@/router";
import newsletterService from "@/services/newsletterService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    newsletters: [],
    ncategories: [],
    loading: false,
  },
  getters: {
    getNewsletters: (state) => state.newsletters,
    getNCategories: (state) => state.ncategories,
  },
  mutations: {
    SET_NEWSLETTER(state, newsletter) {
      state.newsletters = newsletter;
    },
    SET_NEWSLETTER_CATEGORIES(state, ncategories) {
      state.ncategories = ncategories;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initNewsletters({ commit }) {
      try {
        const response = await newsletterService.getAllNewsletters();

        if (response.status === "success") {
          commit("SET_NEWSLETTER", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async initNCategories({ commit }) {
      try {
        const response = await newsletterService.getAllNewsletterCategories();

        if (response.status === "success") {
          commit("SET_NEWSLETTER_CATEGORIES", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createNewsletter({ commit }, newsletter) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: newsletter.title,
          content: newsletter.content,
          en_title: newsletter.en_title,
          en_content: newsletter.en_content,
          image: newsletter.image,
          pdf: newsletter.pdf,
        };

        body.newsletter_category_id = newsletter.newsletter_category.value;

        const response = await newsletterService.createNewsletter(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "newsletter-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async createNewsletterCategory({ commit }, newsletter) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: newsletter.name,
          description: newsletter.description,
          en_description: newsletter.en_description,
        };

        const response = await newsletterService.createNewsletterCategory(body);

        if (response.status === "success") {

          notify({
            type: "success",
            text: response.message,
          });

          router.push({ name: "newsletter-category-list-page" });
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
    async initNewsletter(_, newsletterId) {
      try {
        const response = await newsletterService.getNewsletterById(
          newsletterId
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async initNewsletterCategory(_, ncategoryId) {
      try {
        const response = await newsletterService.getNCategoryById(ncategoryId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateNewsletter(_, newsletter) {
      try {
        const body = {
          title: newsletter.title,
          content: newsletter.content,
          en_title: newsletter.en_title,
          en_content: newsletter.en_content,
          image: newsletter.image,
          pdf: newsletter.pdf,
        };

        body.newsletter_category_id = newsletter.newsletter_category.value;

        const response = await newsletterService.updateNewsletter(
          newsletter.id,
          body
        );

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "newsletter-list-page" });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async updateNewsletterCategory(_, ncategory) {
      try {
        const body = {
          name: ncategory.name,
          description: ncategory.description,
          en_description: ncategory.en_description,
        };

        const response = await newsletterService.updateNewsletterCategory(
          ncategory.id,
          body
        );

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });

          router.push({ name: "newsletter-category-list-page" });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async deleteNewsletter({ commit }, nId) {
      try {
        commit("SET_LOADING", true);
        const response = await newsletterService.deleteNewsletter(nId);

        if (response.status === "success") {
          commit(
            "SET_NEWSLETTER",
            this.state.newsletter.newsletters.filter(
              (newsletter) => newsletter.id !== nId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteNCategory({ commit }, ncId) {
      try {
        commit("SET_LOADING", true);
        const response = await newsletterService.deleteNCategory(ncId);

        if (response.status === "success") {
          commit(
            "SET_NEWSLETTER_CATEGORIES",
            this.state.newsletter.ncategories.filter(
              (ncategory) => ncategory.id !== ncId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
