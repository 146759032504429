import contentService from "@/services/contentService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    content: {},
    loading: false,
  },
  getters: {
    getContent: (state) => state.content,
    getLoading: (state) => state.loading,
  },
  mutations: {
    SET_CONTENT(state, content) {
      state.content = content;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initContent({ commit }, section) {
      commit("SET_LOADING", true);
      try {
        const response = await contentService.initContent(section);

        if (response.status === "success") {
          commit("SET_CONTENT", response.data);
        }

        commit("SET_LOADING", false);
        return response.data;
      } catch (error) {
        commit("SET_LOADING", false);
        return error.response;
      }
    },

    async updateContent(_, form) {
      try {
        const body = {
          section: form.section,
          title: form.title,
          content: form.content,
          en_title: form.en_title,
          en_content: form.en_content,
          status: form.status,
        };

        const response = await contentService.updateContent(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
