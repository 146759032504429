import router from "@/router";
import workService from "@/services/workService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    works: [],
    loading: false,
  },
  getters: {
    getWorks: (state) => state.works,
  },
  mutations: {
    SET_WORKS(state, works) {
      state.works = works;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initWorks({ commit }) {
      try {
        const response = await workService.getAllWorks();

        if (response.status === "success") {
          commit("SET_WORKS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createWork({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          number: form.number,
        };

        const response = await workService.createWork(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "work-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteWork({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await workService.deleteWork(id);

        if (response.status === "success") {
          commit(
            "SET_WORKS",
            this.state.work.works.filter((work) => work.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getWorkById(_, id) {
      try {
        const response = await workService.getWorkById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateWork(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          number: form.number,
        };

        const response = await workService.updateWork(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "work-list-page" });
        }
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
