import { appAxios } from "./appService";

export default {
  async getAllGcomps() {
    const response = await appAxios.get("/get-group-companies");
    return response.data;
  },
  async createGcomp(body) {
    const response = await appAxios.post("/admin/create-group-companie", body);
    return response.data;
  },
  async deleteGcomp(id) {
    const response = await appAxios.delete(`/admin/delete-group-companie/${id}`);
    return response.data;
  },
  async getGcompById(id) {
    const response = await appAxios.get(`/admin/get-group-companie/${id}`);
    return response.data;
  } ,
  async updateGcomp(id, body) { 
    const response = await appAxios.put(`/admin/update-group-companie/${id}`, body);
    return response.data;
  }
};