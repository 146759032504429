export default [
    {
      path: "/people-and-culture",
      name: "pac-page",
      component: () => import("../views/pac/pac-page.vue"),
    },
    {
      path: "/update-mert-life",
      name: "update-mlife-page",
      component: () => import("../views/pac/pages/update-mert-life-page.vue"),
    },
  ];
  