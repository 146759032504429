import sectorService from "@/services/sectorService";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    sectors: [],
    loading: false,
  },
  getters: {
    getSectors: (state) => state.sectors,
  },
  mutations: {
    SET_SECTORS(state, sectors) {
      state.sectors = sectors;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    async initSectors({ commit }) {
      try {
        const response = await sectorService.getAllSectors();
        if (response.status === "success") {
          commit("SET_SECTORS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },


    async createSector({ commit }, form) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: form.name,
          en_name: form.en_name,
        };

        const response = await sectorService.createSector(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "sector-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async deleteSector({ commit }, id) {
      try {
        commit("SET_LOADING", true);
        const response = await sectorService.deleteSector(id);

        if (response.status === "success") {
          commit(
            "SET_SECTORS",
            this.state.sector.sectors.filter((sector) => sector.id !== id)
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },

    async getSectorById(_, id) {
      try {
        const response = await sectorService.getSectorById(id);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async updateSector(_, form) {
      try {
        const body = {
          name: form.name,
          en_name: form.en_name,
        };

        const response = await sectorService.updateSector(form.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "sector-list-page" });
        }

      } catch (error) {
        console.log(error.response);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
  },
};
