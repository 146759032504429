import { appAxios } from "./appService";

export default {
  async getAllAProjects() {
    const response = await appAxios.get("/get-arge-projects");
    return response.data;
  },
  async createAProject(body) {
    const response = await appAxios.post("/admin/create-arge-project", body);
    return response.data;
  },
  async deleteAProject(id) {
    const response = await appAxios.delete(`/admin/delete-arge-project/${id}`);
    return response.data;
  },
  async getAProjectById(id) {
    const response = await appAxios.get(`/admin/get-arge-project/${id}`);
    return response.data;
  } ,
  async updateAProject(id, body) { 
    const response = await appAxios.put(`/admin/update-arge-project/${id}`, body);
    return response.data;
  }
};