export default [
    {
      path: "/solition",
      name: "solition-list-page",
      component: () => import("../views/solition/solition-list-page.vue"),
    },
    {
      path: "/create-solition",
      name: "create-solition-page",
      component: () => import("../views/solition/create-solition-page.vue"),
    },
    {
      path: "/update-solition/:id",
      name: "update-solition-page",
      component: () => import("../views/solition/update-solition-page.vue"),
      props: true
    }
  ];
  