import router from "@/router";
import serviceService from "@/services/serviceService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    services: [],
    loading: false,
  },
  getters: {
    getServices: (state) => state.services,
    getSelectedStatus: (state) => state.selectedStatus,
  },
  mutations: {
    SET_SERVICES(state, services) {
      state.services = services;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initServices({ commit }) {
      try {
        const response = await serviceService.getAllServices();

        if (response.status === "success") {
          commit("SET_SERVICES", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createService({ commit }, service) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: service.name,
          content: service.content,
          en_name: service.en_name,
          en_content: service.en_content,
          image: service.image,
        };

        const response = await serviceService.createService(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "service-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
    async deleteService({ commit }, serviceId) {
      try {
        commit("SET_LOADING", true);
        const response = await serviceService.deleteService(serviceId);

        if (response.status === "success") {
          commit(
            "SET_SERVICES",
            this.state.service.services.filter(
              (service) => service.id !== serviceId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async initService(_, serviceId) {
      try {
        const response = await serviceService.getServiceById(serviceId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateService(_, service) {
      try {
        const body = {
          name: service.name,
          content: service.content,
          en_name: service.en_name,
          en_content: service.en_content,
          image: service.image,
        };

        const response = await serviceService.updateService(service.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "service-list-page" });
        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },
    async uploadCategoryImage(_, image) {
      try {
        let formData = new FormData();
        for (var i = 0; i < image.length; i++) {
          let file = image[i];
          formData.append("images", file);
        }
        const response = await serviceService.uploadCategoryImage(formData);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async uploadServiceImage(_, image) {
      try {
        let formData = new FormData();
        for (var i = 0; i < image.length; i++) {
          let file = image[i];
          formData.append("images", file);
        }
        const response = await serviceService.uploadServiceImage(formData);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
