import { appAxios } from "./appService";

export default {
  async getAllNewsletters() {
    const response = await appAxios.get("/get-newsletters");
    return response.data;
  },
  async getAllNewsletterCategories() {
    const response = await appAxios.get("/get-newsletter-categories");
    return response.data;
  },
  async createNewsletter(body) {
    const response = await appAxios.post("/create-newsletter", body);
    return response.data;
  },
  async createNewsletterCategory(body) {
    const response = await appAxios.post("/create-newsletter-category", body);
    return response.data;
  },
  async deleteNewsletter(nId) {
    const response = await appAxios.delete(`/delete-newsletter/${nId}`);
    return response.data;
  },
  async deleteNCategory(ncId) {
    const response = await appAxios.delete(`/delete-newsletter-category/${ncId}`);
    return response.data;
  },
  async updateNewsletter(newsletterId, body) {
    const response = await appAxios.put(`/update-newsletter/${newsletterId}`, body);
    return response.data;
  },
  async updateNewsletterCategory(ncategoryId, body) {
    const response = await appAxios.put(`/update-newsletter-category/${ncategoryId}`, body);
    return response.data;
  },
  async getNewsletterById(nId) {
    const response = await appAxios.get(`/get-newsletter/${nId}`);
    return response.data;
  },
  async getNCategoryById(ncId) {
    const response = await appAxios.get(`/get-newsletter-category/${ncId}`);
    return response.data;
  }
};
