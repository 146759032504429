import { appAxios } from "./appService";

export default {
  async initContent(section) {
    const response = await appAxios.get(`/admin/get-content-for-admin/${section}`);
    return response.data;
  },
  async updateContent(body) {
    const response = await appAxios.put(`/admin/update-content`, body);
    return response.data;
  },
};
