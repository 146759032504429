export default [
    {
      path: "/works",
      name: "work-list-page",
      component: () => import( "../views/works/work-list-page.vue"),
    },
    {
      path: "/work-create",
      name: "work-create-page",
      component: () => import("../views/works/work-create-page.vue"),
    },
    {
      path: "/work-update/:id",
      name: "work-update-page",
      component: () => import("../views/works/work-update-page.vue"),
      props: true,
    },
  ];
  