import { appAxios } from "./appService";

export default {
  async getAllSolitions() {
    const response = await appAxios.get("/get-solitions");
    return response.data;
  },
  async createSolition(body) {
    const response = await appAxios.post("/admin/create-solition", body);
    return response.data;
  },
  async deleteSolition(solitionId) {
    const response = await appAxios.delete(`/admin/delete-solition/${solitionId}`);
    return response.data;
  },
  async updateSolition(solitionId, body) {
    const response = await appAxios.put(`/admin/update-solition/${solitionId}`, body);
    return response.data;
  },
  async getSolitionById(solitionId) {
    const response = await appAxios.get(`/get-solition/${solitionId}`);
    return response.data;
  },
};
