import router from "@/router";
import productService from "@/services/productService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    products: [],
    loading: false,
  },
  getters: {
    getProducts: (state) => state.products,
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initProducts({ commit }) {
      try {
        const response = await productService.getAllProducts();

        if (response.status === "success") {
          commit("SET_PRODUCTS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },
    async createProduct({ commit }, product) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: product.name,
          description: product.description,
          en_name: product.en_name,
          en_description: product.en_description,
          image: product.image,
          active: product.active,
        };

        const response = await productService.createProduct(body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "product-list-page" });
        }

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
    async deleteProduct({ commit }, productId) {
      try {
        commit("SET_LOADING", true);
        const response = await productService.deleteProduct(productId);

        if (response.status === "success") {
          commit(
            "SET_PRODUCTS",
            this.state.product.products.filter(
              (product) => product.id !== productId
            )
          );
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
      }
    },
    async initProduct(_, productId) {
      try {
        const response = await productService.getProductById(productId);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateProduct(_, product) {
      try {
        const body = {
          name: product.name,
          description: product.description,
          en_name: product.en_name,
          en_description: product.en_description,
          image: product.image,
          active: product.active,
        };

        const response = await productService.updateProduct(product.id, body);

        if (response.status === "success") {
          notify({
            type: "success",
            text: response.message,
          });
          router.push({ name: "product-list-page" });
        }

      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },
  },
};
