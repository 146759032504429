// import router from "@/router";
import mainpageService from "@/services/mainpageService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    feature: null,
    loading: false,
  },
  getters: {
    // getJourneys: (state) => state.journeys,
    getFeature: (state) => state.feature,
    getTotalPages: (state) => state.totalPages,
  },
  mutations: {
    SET_JOURNEY(state, journeys) {
      state.journeys = journeys;
    },
    SET_TOTAL_PAGES(state, totalPages) {
      state.totalPages = totalPages - 1;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initFeature() {
      try {
        const response = await mainpageService.getFeature();

        if (response.status === "success") {
          return response.data;
        }

        return {};
      } catch (error) {
        return error.response;
      }
    },

    async initFounderSlogan(){
      try{
        const response = await mainpageService.getFounderSlogan();

        if(response.status === "success"){
          return response.data;
        }

        return {};
      }catch(error){
        return error.response;
      }
    },

    async initMainAbout(){
      try{
        const response = await mainpageService.getMainAbout();
        console.log(response);

        if(response.status === "success"){
          return response.data;
        }

        return {};
      }catch(error){
        return error.response;
      }
    },

    async updateFeature(_, form) {
      try {
        const body = {
          title: form.title,
          description: form.description,
          en_title: form.en_title,
          en_description: form.en_description,
          isActive: form.isActive,
        };

        const response = await mainpageService.updateFeature(body);

        if (response.status === "success") {
          console.log("Feature updated successfully");

          notify({
            type: "success",
            text: response.message,
          });

        }
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateFounderSlogan(_, form){
      try{
        const body = {
          title: form.title,
          content: form.content,
          en_title: form.en_title,
          en_content: form.en_content,
          name: form.name,
          founder_title: form.founder_title,
          signature: form.signature,
        };

        const response = await mainpageService.updateFounderSlogan(body);

        if(response.status === "success"){
          console.log("Founder slogan updated successfully");

          notify({
            type: "success",
            text: response.message,
          });
        }
      }catch(error){
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    },

    async updateMainAbout(_, form){
      try{
        const body = {
          content: form.content,
          en_content: form.en_content,
          image: form.image,
        };

        const response = await mainpageService.updateMainAbout(body);

        if(response.status === "success"){
          console.log("Main about updated successfully");

          notify({
            type: "success",
            text: response.message,
          });
        }
      }catch(error){
        console.log(error.response.data.error);
        notify({
          type: "error",
          text: error.response.data.error,
        });
      }
    }


  },
};
